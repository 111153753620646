<template>
  <div id="products-list-list-view" class="products-list-container">
    <!-- BREADCRUMB -->
    <breadcrumb-base :breadcrumb="breadcrumb" :title="'Products'">
      <template v-slot:right-end>
        <div class="vx-row">
          <vs-button class="alpha-clear" v-if="selectedAlpha" color="warning" type="flat"
                     icon-pack="feather" icon="icon-x" size="small"
                     @click="clearSelectedAlpha()"></vs-button>
          <vs-pagination-modified v-model="selectedAlphaCurrentX" :total="26" :max="26"
                                  v-on:change="pageChanged"></vs-pagination-modified>
          <div class="p-3"></div>
          <vs-button @click="openAddProductPage" type="filled" icon-pack="feather"
                     icon="icon-plus">{{ $t('AddProduct') }}
          </vs-button>
        </div>
      </template>
    </breadcrumb-base>

    <transition name="fade">
      <div class="vx-row">
        <div class="vx-col md:w-1/5 mt-20">
          <vx-card>
            <div class="vx-col m-3" v-for="(item, index) in productsFilters">
              <vs-row class="vs-row">
                <h4>{{item.value | title}}</h4>
<!--                <vs-button class="ml-2" v-if="filterAccountStatus[item.value]" color="warning"-->
<!--                           type="flat" icon-pack="feather" icon="icon-x" size="normal"-->
<!--                           @click="clearFilter(item.value)">{{ $t('Clear') }}-->
<!--                </vs-button>-->
              </vs-row>
              <ul class="centerx mt-4">
                <li v-for="(option, index) in item.options">
                  <vs-radio v-model="filterAccountStatus[item.value]" v-on:change="filterChanged"
                            :vs-value="option">{{option}}
                  </vs-radio>
                </li>
              </ul>
              <vs-divider v-if="productsFilters.length > 0 && index !== productsFilters.length-1"/>
            </div>
            <vs-button v-if="filterAccountStatus" class="mt-3 w-full py-4 px-3" color="primary"
                       @click="clearAllFilter()">{{$t('ClearAllFilters').toUpperCase()}}
            </vs-button>
          </vx-card>
        </div>
        <div class="vx-col md:w-4/5">

          <vs-row class="">
            <vs-spacer></vs-spacer>

            <div
              class="con-input-search vs-table--search">
              <vs-input
                class="input-search"
                @input="searchDebounce"
                v-model="searchText"
                type="text"
                icon-no-border icon="search" />
            </div>
          </vs-row>
          <!--    Empty List State -->
          <transition name="fade">
            <div class="h-screen flex w-full bg-img" v-if="products.length === 0 && !errorFetching">
              <div
                class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
<!--                <img src="@/assets/images/pages/svg_blank.svg" :alt="$t('NoData')"-->
<!--                     class="mx-auto mb-4 max-w-full">-->
                <h1 class="sm:mx-0 mx-4 mb-12 text-3xl">{{$t('NoData')}}</h1>
              </div>
            </div>
          </transition>
          <vs-table-modified v-show="products.length > 0 && !errorFetching" ref="table"
                             :max-items="itemsPerPage"
                             :data="products"
          >
            <!--                             v-on:selected="openProductDetailPage"-->

            <template slot="thead">
<!--              <vs-th style="max-width: 72px;" >{{$t('ID')}}</vs-th>-->
              <vs-th>{{$t('Image')}}</vs-th>
              <vs-th >{{$t('Company')}}</vs-th>
              <vs-th >{{$t('ProductType')}}</vs-th>
              <vs-th>{{$t('TotalDenominations')}}</vs-th>
              <vs-th>{{ $t('Action') }}</vs-th>
            </template>

            <template slot-scope="{data}">
              <tbody>
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

<!--                <vs-td>-->
<!--                  <p class="product-name font-medium truncate">{{ tr.product_id | capitalize }}</p>-->
<!--                </vs-td>-->

                <vs-td>
                  <img :src="tr.product_image" :alt="tr.product_name" class="product-image"/>
                </vs-td>

                <vs-td>
                  <p class="product-name">{{ tr.product_name | title }}</p>
                </vs-td>

                <vs-td>
                  <p class="product-type">{{ tr.product_type | title }}</p>
                </vs-td>

                <vs-td>
                  <p class="" style="font-weight: bold;">{{ tr.configurations.length }}</p>
                </vs-td>


                <vs-td class="whitespace-no-wrap">
                  <vs-button class="" type="border" @click="openProductDetailPage(tr)">{{
                    $t('Details') }}
                  </vs-button>
                </vs-td>

              </vs-tr>
              </tbody>
            </template>
            <template slot="footer">
<!--              <vs-button @click="loadMoreProducts" type="border" v-if="(vPage+1) < vTotalPages">Load-->
<!--                More-->
<!--              </vs-button>-->
            </template>
            <template slot="footer-left">
              <div class="flex">
                <vs-new-pagination :total="vTotalPages" v-model="cPage" v-on:change="listPageChanged">
                </vs-new-pagination>
              </div>
            </template>
          </vs-table-modified>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>

export default {
  name: 'ViewProducts',
  data() {
    return {
      searchText: '',
      itemsPerPage: 50,
      cPage: 1,
      isMounted: false,
      errorFetching: false,
      filterAccountStatus: {},
      selectedAlpha: null,
      selectedAlphaCurrentX: 1,
      alpha: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
      breadcrumb: [
        { title: 'Home', i18n: 'Home', url: '/' },
        { title: 'Products', i18n: 'Products', active: true },
      ],
    }
  },
  computed: {
    vPage() {
      return this.$store.state.products.vPage
    },
    vTotalPages() {
      return this.$store.state.products.vTotalPages
    },
    products() {
      return this.$store.state.products.products
    },
    productsRaw() {
      return this.$store.state.products.productsRaw
    },
    productsFilters() {
      return this.$store.state.products.productsFilters
    },
    queriedItems() {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.products.length
    },
  },
  methods: {
    openProductDetailPage(item) {
      this.$router.push({ name: 'products-view-detail', params: { productId: item.product_id } })
    },
    clearSelectedAlpha() {
      this.selectedAlpha = null
      this.selectedAlphaCurrentX = 1

      this.loadProducts(0, this.getFilters(), this.selectedAlpha)
    },
    clearFilter(key) {
      this.filterAccountStatus[key] = null
      this.filterChanged()
    },
    clearAllFilter() {
      this.filterAccountStatus = {}
      this.filterChanged()
    },
    pageChanged(newIndex) {
      if (isNaN(newIndex)) {
        this.selectedAlpha = newIndex
        this.selectedAlphaCurrentX = newIndex
        this.loadProducts(0, this.getFilters(), newIndex)
      }
    },

    searchDebounce() {
      clearTimeout(this.interval)
      this.interval = setTimeout(() => {
        this.loadProducts('0', this.getFilters(), this.searchText)
      }, 500)
    },
    listPageChanged(item) {
      this.loadProducts(this.cPage - 1, this.getFilters(), '')
    },
    getFilters() {
      const filter = {}
      Object.keys(this.filterAccountStatus).forEach((key) => {
        if (this.filterAccountStatus[key]) {
          filter[key] = this.filterAccountStatus[key]
        }
      })
      return filter
    },
    filterChanged() {
      this.loadProducts(0, this.getFilters(), this.selectedAlpha || this.searchText)
    },
    openAddProductPage() {
      this.$router.push({ name: 'products-add' })
    },
    loadProducts(page = '0', filter = null, search = null) {
      this.$vs.loading()
      return this.$store.dispatch('products/fetchProducts', { page, filter, search })
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
        })
        .catch((error) => {
          this.$vs.loading.close()
          this.errorFetching = true
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
  },
  created() {
    // this.loadProducts()
  },
  mounted() {
    this.isMounted = true
  },
}
</script>

<style lang="scss">
  .vs-button.small {
    padding: 7px 0;
    font-size: .7em;
  }

  .alpha-clear {
    margin-top: 0.5rem;
    padding: 5px;
  }

  .vs-button.small:not(.includeIconOnly) {

    padding: 7px 0;
  }

  #products-list-list-view {
    .product-image {
      /*max-width: 200px;*/
      max-height: 40px;
      object-fit: cover;
    }
    .td .vs-table--td {
      display: flex;
      justify-content: center;
    }

    .vs-con-table {

      /*
        Below media-queries is fix for responsiveness of action buttons
        Note: If you change action buttons or layout of this page, Please remove below style
      */
      @media (max-width: 689px) {
        .vs-table--search {
          margin-left: 0;
          max-width: unset;
          width: 100%;

          .vs-table--search-input {
            width: 100%;
          }
        }
      }

      @media (max-width: 461px) {
        .items-per-page-handler {
          display: none;
        }
      }

      @media (max-width: 341px) {
        .products-list-btn-container {
          width: 100%;

          .dd-actions,
          .btn-add-new {
            width: 100%;
            margin-right: 0 !important;
          }
        }
      }

      .product-name {
        max-width: 23rem;
      }

      .vs-table--header {
        display: flex;
        flex-wrap: wrap;
        margin-left: 1.5rem;
        margin-right: 1.5rem;

        > span {
          display: flex;
          flex-grow: 1;
        }

        .vs-table--search {
          padding-top: 0;

          .vs-table--search-input {
            padding: 0.9rem 2.5rem;
            font-size: 1rem;

            & + i {
              left: 1rem;
            }

            &:focus + i {
              left: 1rem;
            }
          }
        }
      }

      .vs-table {
        border-collapse: separate;
        border-spacing: 0 1.3rem;
        padding: 0 1rem;

        tr {
          box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .05);

          td {
            padding: 20px;

            &:first-child {
              border-top-left-radius: .5rem;
              border-bottom-left-radius: .5rem;
            }

            &:last-child {
              border-top-right-radius: .5rem;
              border-bottom-right-radius: .5rem;
            }
          }

          td.td-check {
            padding: 20px !important;
          }
        }
      }

      .vs-table--thead {
        th {
          padding-top: 0;
          padding-bottom: 0;

          .vs-table-text {
            text-transform: uppercase;
            font-weight: 600;
          }
        }

        th.td-check {
          padding: 0 15px !important;
        }

        tr {
          background: none;
          box-shadow: none;
        }
      }

      .vs-table--pagination {
        justify-content: center;
      }
    }
  }
</style>
